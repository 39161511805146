.certificates {
  background-color: #dbdbdb;
  padding: 80px 80px 0 80px;
  flex-direction: column;
  text-align: center;
  display: flex;
}

@media screen and (max-width: 600px) {
  .certificates {
    padding: 0 40px 0 40px;
  }
}

@media screen and (max-width: 450px) {
  .certificates {
    padding: 0;
  }
}
