.contact-box__title {
  margin: 0;
  margin-bottom: 20px;
  font-size: 24px;
}

@media screen and (max-width: 880px) {
  .contact-box__title {
    font-size: 22px;
  }
}

@media screen and (max-width: 500px) {
  .contact-box__title {
    font-size: 20px;
  }
}

@media screen and (max-width: 320px) {
  .contact-box__title {
    font-size: 18px;
  }
}
