.title {
  font-size: 50px;
}

@media screen and (max-width: 1023px) {
  .title {
    font-size: 30px;
  }
}

@media screen and (max-width: 880px) {
  .title {
    font-size: 26px;
  }
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 22px;
  }
}

@media screen and (max-width: 320px) {
  .title {
    font-size: 18px;
  }
}
