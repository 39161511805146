.subsection__headshot-container__headshot {
  border-radius: 50%;
  margin-left: 80px;
  max-width: 300px;
}

@media screen and (max-width: 1023px) {
  .subsection__headshot-container__headshot {
    margin-left: 60px;
    width: 200px;
  }
}

@media screen and (max-width: 880px) {
  .subsection__headshot-container__headshot {
    width: 150px;
    margin: 0;
  }
}

@media screen and (max-width: 500px) {
  .subsection__headshot-container__headshot {
    width: 100px;
  }
}
