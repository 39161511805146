.footer {
  background-color: #1c1d25;
  justify-content: center;
  padding: 40px 0;
  display: flex;
}

@media screen and (max-width: 880px) {
  .footer {
    padding: 30px 0;
  }
}

@media screen and (max-width: 500px) {
  .footer {
    padding: 20px 0;
  }
}

@media screen and (max-width: 320px) {
  .footer {
    padding: 10px 0;
  }
}
