.subsection__information__subtitle {
  font-weight: 300;
  max-width: 700px;
  font-size: 30px;
  margin: 0;
}

@media screen and (max-width: 1023px) {
  .subsection__information__subtitle {
    font-size: 20px;
  }
}

@media screen and (max-width: 880px) {
  .subsection__information__subtitle {
    font-size: 18px;
  }
}

@media screen and (max-width: 500px) {
  .subsection__information__subtitle {
    font-size: 16px;
  }
}

@media screen and (max-width: 320px) {
  .subsection__information__subtitle {
    font-size: 14px;
    max-width: 180px;
  }
}
