.hero-area__wrapper {
  margin-bottom: 200px;
  margin-left: 80px;
}

@media screen and (max-width: 1023px) {
  .hero-area__wrapper {
    margin-left: 50px;
  }
}

@media screen and (max-width: 880px) {
  .hero-area__wrapper {
    margin-left: 20px;
  }
}
