.hero-area__wrapper__profession {
  font-weight: 300;
  margin-top: 20px;
  font-size: 30px;
}

@media screen and (max-width: 1023px) {
  .hero-area__wrapper__profession {
    margin-top: 10px;
    font-size: 25px;
  }
}

@media screen and (max-width: 880px) {
  .hero-area__wrapper__profession {
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  .hero-area__wrapper__profession {
    font-size: 16px;
  }
}

@media screen and (max-width: 320px) {
  .hero-area__wrapper__profession {
    font-size: 14px;
  }
}