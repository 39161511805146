.locale {
  background-image: url("../../images/russian-flag.png");
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 0;
}

@media only screen and (max-width: 500px) {
  .locale {
    height: 20px;
    width: 20px;
  }
}