.popup__container {
  box-shadow: 0 0 25px rgb(0 0 0.15);
  justify-content: space-between;
  background-color: #ffffff;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  position: relative;
  display: flex;
  height: 360px;
  width: 450px;
}
