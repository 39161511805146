.certificates__card__item {
  box-shadow: 5px 5px 20px rgb(197, 195, 195);
  object-fit: cover;
  border-radius: 5%;
  cursor: pointer;
  display: block;
  width: 100%;
}

.certificates__card__item:hover {
  box-shadow: 5px 5px 20px rgba(48, 48, 48, 0.7);
}
