.projects__project-container {
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-around;
  justify-items: center;
  display: grid;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 1281px) {
  .projects__project-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 970px) {
  .projects__project-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 670px) {
  .projects__project-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
